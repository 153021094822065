import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Line } from 'react-chartjs-2';
import { ArrowLeft, TrendingUp } from 'react-feather';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { 
  fetchSensorHistoricalData,
  updateSensorName,
  updateSensorLocationDescription,
  updateSensorNotes,
  fetchAllSensorInfo
} from '../api';
import { BASE_URL } from '../config';
import L from 'leaflet';
import api from '../api';
import './SensorDataPage.css';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Import the red pinpoint icon
import redPinpointIcon from '../assets/pinpoint-active.png';

// Constants
const FIXED_HEIGHT = 600;

// Styles 
const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#1e1e1e',
    color: '#ffffff',
    minHeight: '100vh',
  },
  mapAndInfoContainer: {
    display: 'flex',
    marginBottom: '20px',
  },
  mapContainer: {
    flex: '0 0 33.333%', // 1/3 of the row width
    marginRight: '20px',
  },
  map: {
    height: '100%',
    width: '100%',
  },
  mapPlaceholder: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#2c2c2c',
    color: '#ffffff',
    fontSize: '18px',
  },
  infoBox: {
    flex: '0 0 66.666%', // 2/3 of the row width
    height: `${FIXED_HEIGHT}px`, // Fixed height
    backgroundColor: '#2c2c2c',
    borderRadius: '10px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  tabButtonsContainer: {
    display: 'flex',
    backgroundColor: '#2c2c2c',
    borderBottom: '1px solid #444',
    overflow: 'hidden',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  tabContentContainer: {
    flex: 1,
    overflow: 'auto', // Make the content scrollable if it exceeds the container height
    padding: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#2c2c2c',
  },
  tableHeader: {
    backgroundColor: '#3c3c3c',
    color: '#fff',
    padding: '15px',
    textAlign: 'left',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '14px',
    letterSpacing: '1px',
  },
  tableRowEven: {
    backgroundColor: '#2c2c2c',
  },
  tableRowOdd: {
    backgroundColor: '#333333',
  },
  tableCell: {
    padding: '12px 15px',
    borderBottom: '1px solid #444',
    transition: 'background-color 0.3s',
  },
  tableRowHover: {
    backgroundColor: '#4a4a4a',
    cursor: 'pointer',
  },
  backButton: {
    background: 'none',
    border: 'none',
    color: '#fff',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '10px',
    borderRadius: '5px',
    transition: 'background-color 0.3s'
  },
  tab: {
    flex: 1,
    padding: '15px 20px',
    fontSize: '14px',
    fontWeight: '500',
    border: 'none',
    borderBottom: '3px solid transparent',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    backgroundColor: 'transparent',
    color: '#aaaaaa',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
  },
  activeTab: {
    color: '#4CAF50',
    borderBottom: '3px solid #4CAF50',
    backgroundColor: '#3c3c3c',
  },
  exportButton: {
    alignSelf: 'flex-start',
  },
  filterButton: {
    margin: '0 5px',
  },
  activeTab: {
    backgroundColor: '#4CAF50',
  },
  exportContainer: {
    backgroundColor: '#2c2c2c',
    borderRadius: '10px',
    padding: '30px',
    marginTop: '30px',
    width: '100%',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  exportHeading: {
    color: '#4CAF50',
    marginBottom: '25px',
    fontSize: '24px',
  },
  exportForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  exportDateRange: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
  },
  exportField: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  exportLabel: {
    color: '#ffffff',
    marginBottom: '8px',
    fontSize: '14px',
  },
  exportInput: {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #4CAF50',
    backgroundColor: '#3c3c3c',
    color: '#ffffff',
    fontSize: '14px',
  },
  exportOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '40px',
  },
  exportDataSelection: {
    flex: 1,
  },
  exportFormat: {
    flex: 1,
  },
  exportSubHeader: {
    fontSize: '18px',
    color: '#4CAF50',
    marginBottom: '15px',
  },
  exportCheckboxLabel: {
    display: 'flex',
    alignItems: 'center',
    color: '#ffffff',
    fontSize: '14px',
    marginBottom: '10px',
  },
  exportCheckbox: {
    marginRight: '10px',
  },
  exportRadioGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  exportRadioLabel: {
    display: 'flex',
    alignItems: 'center',
    color: '#ffffff',
    fontSize: '14px',
  },
  exportRadio: {
    marginRight: '10px',
  },
  exportButton: {
    padding: '12px 24px',
    backgroundColor: '#4CAF50',
    color: '#ffffff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    transition: 'background-color 0.3s',
  },
  liveMeasurementsContainer: {
    display: 'flex',
    height: '100%',
  },
  liveMeasurementsList: {
    flex: '1 1 50%',
    overflowY: 'auto',
    paddingRight: '10px',
  },
  trendChartContainer: {
    flex: '1 1 50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center the chart vertically
    padding: '0 20px', // Add some padding
  },
  subHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#4CAF50',
    marginBottom: '20px',
    borderBottom: '2px solid #4CAF50',
    paddingBottom: '10px',
  },
  lastUpdated: {
    fontSize: '14px',
    color: '#aaaaaa',
    marginBottom: '15px',
  },
  measurementItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#3c3c3c',
    marginBottom: '10px',
  },
  measurementLabel: {
    flex: '0 0 120px',
    fontWeight: 'bold',
    color: '#ffffff',
  },
  measurementValue: {
    flex: '1 1 auto',
    textAlign: 'right',
    marginRight: '10px',
    color: '#4CAF50',
    fontWeight: 'bold',
  },
  trendButton: {
    background: 'none',
    border: 'none',
    color: '#4CAF50',
    cursor: 'pointer',
    padding: '5px',
    borderRadius: '50%',
    transition: 'background-color 0.3s',
  },
  activeTrendButton: {
    background: 'rgba(76, 175, 80, 0.2)',
    border: 'none',
    color: '#4CAF50',
    cursor: 'pointer',
    padding: '5px',
    borderRadius: '50%',
    transition: 'background-color 0.3s',
  },
  trendChartTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#ffffff',
  },
  trendChartWrapper: {
    width: '100%',
    paddingBottom: '100%', // This creates a square aspect ratio
    position: 'relative',
  },
  trendChart: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  noData: {
    color: '#aaaaaa',
    fontStyle: 'italic',
  },
  showDataContainer: {
    backgroundColor: '#2c2c2c',
    borderRadius: '10px',
    padding: '30px',
    marginTop: '30px',
    width: '100%',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  showDataHeading: {
    color: '#4CAF50',
    marginBottom: '25px',
    fontSize: '24px',
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  tabButtonsContainer: {
    display: 'flex',
    gap: '10px',
  },
  tabContent: {
    backgroundColor: '#3c3c3c',
    borderRadius: '5px',
    padding: '20px',
  },
  chartContainer: {
    width: '100%',
    height: 'auto', // This allows the container to adjust to the chart's height
  },
  tableContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
  },
  filterButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  filterButton: {
    padding: '8px 16px',
    margin: '0 5px',
    backgroundColor: '#3c3c3c',
    color: '#ffffff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'background-color 0.3s',
  },
  activeFilterButton: {
    backgroundColor: '#4CAF50',
  },
  // Updated button styles
  buttonBase: {
    padding: '10px 16px',
    fontSize: '14px',
    fontWeight: '500',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    outline: 'none',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
  },
  primaryButton: {
    backgroundColor: '#4CAF50',
    color: '#ffffff',
    boxShadow: '0 2px 4px rgba(76, 175, 80, 0.3)',
  },
  secondaryButton: {
    backgroundColor: '#3c3c3c',
    color: '#ffffff',
    boxShadow: '0 2px 4px rgba(60, 60, 60, 0.3)',
  },
  activeButton: {
    backgroundColor: '#45a049',
    boxShadow: '0 1px 2px rgba(76, 175, 80, 0.5)',
    transform: 'translateY(1px)',
  },
  sensorInfoContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '20px',
    padding: '20px',
    backgroundColor: '#2c2c2c',
    borderRadius: '10px',
  },
  sensorInfoItem: {
    backgroundColor: '#3c3c3c',
    borderRadius: '8px',
    padding: '15px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
  },
  sensorInfoLabel: {
    display: 'block',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#4CAF50',
    marginBottom: '5px',
    textTransform: 'uppercase',
  },
  sensorInfoValue: {
    display: 'block',
    fontSize: '16px',
    color: '#ffffff',
    wordBreak: 'break-word',
  },
  helpText: {
    fontSize: '12px',
    color: '#aaaaaa',
    marginTop: '5px',
    fontStyle: 'italic',
  },
  sensorNameUpdateContainer: {
    marginTop: '20px',
  },
  sensorNameUpdateHeader: {
    fontSize: '18px',
    marginBottom: '10px',
  },
  sensorNameInputContainer: {
    display: 'flex',
    gap: '10px',
  },
  sensorNameInput: {
    flex: 1,
    padding: '8px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #4CAF50',
    backgroundColor: '#2c2c2c',
    color: '#ffffff',
  },
  notesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  noteSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  noteHeader: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  noteTextarea: {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #444',
    backgroundColor: '#2c2c2c',
    color: '#fff',
    resize: 'vertical',
  },
  controlsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    padding: '15px',
    backgroundColor: '#2c2c2c',
    borderRadius: '10px',
  },
  checkboxContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#ffffff',
    fontSize: '14px',
    position: 'relative',
    paddingLeft: '30px',
  },
  checkboxInput: {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    height: 0,
    width: 0,
  },
  periodButtonsContainer: {
    display: 'flex',
    gap: '10px',
  },
  periodButton: {
    padding: '8px 16px',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    backgroundColor: '#3c3c3c',
    color: '#ffffff',
    fontSize: '14px',
    transition: 'all 0.3s ease',
    outline: 'none',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      backgroundColor: '#4a4a4a',
    },
  },
  activePeriodButton: {
    backgroundColor: '#4CAF50',
    boxShadow: '0 2px 4px rgba(76, 175, 80, 0.3)',
    '&:hover': {
      backgroundColor: '#45a049',
    },
  },
};

const formatMeasurement = (value) => {
  if (value === null || value === undefined) {
    return 'N/A';
  }
  return typeof value === 'number' ? value.toFixed(2) : value.toString();
};

const SensorDataPage = () => {
  const { sensorId } = useParams();
  const navigate = useNavigate();
  const [sensorInfo, setSensorInfo] = useState(null);
  const [sensorMetadata, setSensorMetadata] = useState(null);
  const [liveMeasurements, setLiveMeasurements] = useState(null);
  const [filter, setFilter] = useState('7_days');
  const [activeCapabilities, setActiveCapabilities] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [healthData, setHealthData] = useState(null);
  const [sensorLocation, setSensorLocation] = useState(null);
  const [activeTopTab, setActiveTopTab] = useState('liveMeasurements');
  const [activeBottomTab, setActiveBottomTab] = useState('chart');
  const [fromDate, setFromDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedCapabilities, setSelectedCapabilities] = useState({});
  const chartRef = useRef(null);
  const [selectedMeasurement, setSelectedMeasurement] = useState(null);
  const [trendData, setTrendData] = useState([]);
  const trendChartRef = useRef(null);
  const mapContainerRef = useRef(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [exportFormat, setExportFormat] = useState('JSON');
  const [exportData, setExportData] = useState({});
  //const [dataTypes, setDataTypes] = useState([]);
  const [recordedData, setRecordedData] = useState({});
  const [trendChartData, setTrendChartData] = useState({
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: '#4CAF50',
      tension: 0.1,
      pointRadius: 2,
    }]
  });
  const [trendChartOptions, setTrendChartOptions] = useState({
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1, // This makes the chart square
    scales: {
      x: { title: { display: true, text: 'Date' } },
      y: { title: { display: true, text: 'Value' } }
    }
  });
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 4,
    scales: {
      x: { title: { display: true, text: 'Time' } },
      y: { title: { display: true, text: 'Value' } }
    }
  });
  const [newSensorName, setNewSensorName] = useState('');
  const [dataType, setDataType] = useState('temperature');
  const [locationDescription, setLocationDescription] = useState('');
  const [customerNotes, setCustomerNotes] = useState('');
  const [lastUpdated, setLastUpdated] = useState(null);
  const [sensorData, setSensorData] = useState([]);
  const [period, setPeriod] = useState('24_hours');
  const [selectedDataTypes, setSelectedDataTypes] = useState([]);
  const [allMeasurements, setAllMeasurements] = useState([]);
  const [availableDataTypes, setAvailableDataTypes] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const customIcon = new L.Icon({
    iconUrl: redPinpointIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAllSensorInfo(sensorId);
        console.log('Fetched sensor data:', data);

        if (data) {
          setSensorInfo({
            id: data.sensor_id,
            name: data.name,
          });
          setSensorMetadata(data.metadata);
          setLiveMeasurements(data.latest_measurements);
          setHealthData(data.latest_health_data);
          
          // Update sensorLocation
          if (data.latest_measurements.gps_location) {
            setSensorLocation(data.latest_measurements.gps_location.value);
          }

          // Set lastUpdated (assuming it's the most recent timestamp from measurements)
          const timestamps = Object.values(data.latest_measurements)
            .map(m => m.timestamp)
            .filter(t => t);
          if (timestamps.length > 0) {
            setLastUpdated(Math.max(...timestamps.map(t => new Date(t))));
          }

          // Extract data types from the latest_measurements object
          const types = Object.keys(data.latest_measurements).filter(key => key !== 'gps_location');
          setAvailableDataTypes(prevTypes => {
            const newTypes = {};
            types.forEach(type => {
              newTypes[type] = prevTypes[type] || { label: type, unit: 'Unknown' };
            });
            return newTypes;
          });
          
          // Set initial dataType and fetch trend data if not already set
          if (!selectedMeasurement && data.latest_measurements) {
            const firstMeasurement = Object.keys(data.latest_measurements).find(key => key !== 'gps_location');
            if (firstMeasurement) {
              setSelectedMeasurement(firstMeasurement);
              fetchTrendData(firstMeasurement);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching sensor data:', error);
      }
    };

    fetchData();
  }, [sensorId]);

  useEffect(() => {
    if (allMeasurements.length > 0 && activeBottomTab === 'chart') {
      const chartData = prepareChartData();
      setChartData(chartData);
    }
  }, [allMeasurements, activeBottomTab, selectedDataTypes]);

  useEffect(() => {
    if (selectedMeasurement) {
      fetchTrendData(selectedMeasurement);
    }
  }, [selectedMeasurement]);

  useEffect(() => {
    if (mapContainerRef.current) {
      mapContainerRef.current.style.height = `${FIXED_HEIGHT}px`;
    }
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        await fetchSensorMeasurements(period);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [sensorId, period]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchSensorHistoricalData(sensorId, dataType, period);
        // Process and set the data in your component state
        // For example:
        // setChartData(processDataForChart(data));
        // setTableData(processDataForTable(data));
      } catch (error) {
        console.error('Error fetching sensor data:', error);
        // Handle the error appropriately
      }
    };

    fetchData();
  }, [sensorId, dataType, period]);

  const fetchTrendData = async (measurement) => {
    try {
      // Fetch 7-day trend data for the selected measurement
      const response = await api.get(`/sensors/get_7day_trend/${sensorId}/${measurement}/`);
      const trendData = response.data;

      // Process the data for the chart
      const chartData = trendData.trend_data.map(point => ({
        date: new Date(point.timestamp),
        value: point.value
      }));

      setTrendData(chartData);

      setTrendChartData({
        labels: chartData.map(d => d.date.toLocaleDateString()),
        datasets: [{
          label: trendData.label,
          data: chartData.map(d => d.value),
          borderColor: '#4CAF50',
          tension: 0.1,
          pointRadius: 2,
        }]
      });

      // Update chart options with the correct unit
      setTrendChartOptions(prevOptions => ({
        ...prevOptions,
        scales: {
          ...prevOptions.scales,
          y: {
            ...prevOptions.scales.y,
            title: {
              display: true,
              text: `${trendData.label} (${trendData.unit})`
            }
          }
        }
      }));

    } catch (error) {
      console.error('Error fetching trend data:', error);
      // Handle the error appropriately, e.g., show an error message to the user
    }
  };

  const handleBack = () => {
    navigate('/');
  };

  const getRandomColor = () => {
    return '#' + Math.floor(Math.random()*16777215).toString(16);
  };

  const formatSensorValue = (value) => {
    if (value === null || value === undefined) {
      return 'N/A';
    }
    return typeof value === 'number' ? value.toFixed(2) : value.toString();
  };

  const formatLastUpdated = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp);
    return date.toLocaleString(); // This will format the date and time according to the user's locale
  };

  const handleExport = () => {
    // Implement the actual export logic here using the API
    console.log(`Exporting data from ${startDate} to ${endDate}`);
    console.log('Export format:', exportFormat);
    console.log('Export data:', exportData);
    // Call your API function here
  };

  const handleUpdateSensorName = async () => {
    if (newSensorName.trim() === '') return;

    try {
      const updatedName = await updateSensorName(sensorId, newSensorName.trim());
      setSensorInfo(prevInfo => ({ ...prevInfo, name: updatedName }));
      setNewSensorName('');
      alert('Sensor name updated successfully!');
      
      // Refresh the page
      window.location.reload();
    } catch (error) {
      console.error('Error updating sensor name:', error);
      alert('An error occurred while updating the sensor name.');
    }
  };

  const handleDataTypeChange = (newDataType) => {
    setDataType(newDataType);
  };

  const handleUpdateLocationDescription = async () => {
    try {
      await updateSensorLocationDescription(sensorId, locationDescription.trim());
      alert('Location description updated successfully!');
      window.location.reload();
    } catch (error) {
      console.error('Error updating location description:', error);
      alert('An error occurred while updating the location description.');
    }
  };

  const handleUpdateCustomerNotes = async () => {
    try {
      await updateSensorNotes(sensorId, customerNotes.trim());
      alert('Customer notes updated successfully!');
      window.location.reload();
    } catch (error) {
      console.error('Error updating customer notes:', error);
      alert('An error occurred while updating the customer notes.');
    }
  };

  const renderTopTabContent = () => {
    switch (activeTopTab) {
      case 'liveMeasurements':
        return (
          <div style={styles.liveMeasurementsContainer}>
            <div style={styles.liveMeasurementsList}>
              <h3 style={styles.subHeader}>Live Measurements</h3>
              {liveMeasurements && Object.keys(liveMeasurements).length > 0 ? (
                <>
                  <div style={styles.lastUpdated}>
                    Last Updated: {formatLastUpdated(lastUpdated)}
                  </div>
                  {Object.entries(liveMeasurements).map(([key, data]) => (
                    key !== 'gps_location' && (
                      <div key={key} style={styles.measurementItem}>
                        <span style={styles.measurementLabel}>
                          {data.label}
                        </span>
                        <span style={styles.measurementValue}>
                          {formatSensorValue(data.value)} {data.unit}
                        </span>
                        <button 
                          onClick={() => setSelectedMeasurement(key)}
                          style={selectedMeasurement === key ? styles.activeTrendButton : styles.trendButton}
                          title={`Show trend for ${data.label}`}
                        >
                          <TrendingUp size={16} />
                        </button>
                      </div>
                    )
                  ))}
                </>
              ) : (
                <p style={styles.noData}>No live measurements available for this sensor.</p>
              )}
            </div>
            <div style={styles.trendChartContainer}>
              <h4 style={styles.trendChartTitle}>
                7-Day Trend: {selectedMeasurement || 'No measurement selected'}
              </h4>
              <div style={styles.trendChartWrapper}>
                <div style={styles.trendChart}>
                  <Line data={trendChartData} options={trendChartOptions} />
                </div>
              </div>
            </div>
          </div>
        );
      case 'sensorInfo':
        return (
          <div>
            <h3 style={styles.subHeader}>Sensor Information</h3>
            {sensorMetadata && (
              <div style={styles.sensorInfoContainer}>
                {Object.entries(sensorMetadata).map(([key, data]) => (
                  <InfoItem 
                    key={key} 
                    label={key.replace(/_/g, ' ')} 
                    value={formatSensorInfoValue(data.value)} 
                    helpText={data.help_text} 
                  />
                ))}
              </div>
            )}
          </div>
        );
      case 'sensorHealth':
        return (
          <div>
            <h3 style={styles.subHeader}>Sensor Health</h3>
            {healthData && Object.keys(healthData).length > 0 ? (
              <div style={styles.sensorInfoContainer}>
                {Object.entries(healthData).map(([key, data]) => (
                  <InfoItem key={key} label={data.label || key} value={`${data.value} ${data.unit || ''}`} />
                ))}
              </div>
            ) : (
              <p style={styles.noData}>No health data available for this sensor.</p>
            )}
          </div>
        );
      case 'alerts':
        return (
          <div>
            <h3 style={styles.subHeader}>Alerts</h3>
            {/* Add alerts content here */}
            <p style={styles.noData}>No alerts available for this sensor.</p>
          </div>
        );
      case 'notes':
        return (
          <div>
            <h3 style={styles.subHeader}>Sensor Notes</h3>
            <div style={styles.notesContainer}>
              <div style={styles.noteSection}>
                <h4 style={styles.noteHeader}>Location Description</h4>
                <textarea
                  value={locationDescription}
                  onChange={(e) => setLocationDescription(e.target.value)}
                  style={styles.noteTextarea}
                  rows={5}
                />
                <button
                  onClick={handleUpdateLocationDescription}
                  style={{...styles.buttonBase, ...styles.primaryButton}}
                >
                  Update Location Description
                </button>
              </div>
              <div style={styles.noteSection}>
                <h4 style={styles.noteHeader}>Customer Notes</h4>
                <textarea
                  value={customerNotes}
                  onChange={(e) => setCustomerNotes(e.target.value)}
                  style={styles.noteTextarea}
                  rows={5}
                />
                <button
                  onClick={handleUpdateCustomerNotes}
                  style={{...styles.buttonBase, ...styles.primaryButton}}
                >
                  Update Customer Notes
                </button>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  // ========================================================
  //=============== CHART AND TABLE CONTAINER ===============
  // ========================================================
  const renderBottomTabContent = () => {
    const chartData = prepareChartData();

    return (
      <div>
        <div style={styles.controlsContainer}>
          <div style={styles.checkboxContainer}>
            {Object.entries(availableDataTypes).map(([dataType, info]) => (
              <label key={dataType} className="checkbox-label" style={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  checked={selectedDataTypes.includes(dataType)}
                  onChange={() => handleDataTypeToggle(dataType)}
                  style={styles.checkboxInput}
                />
                <span className="checkbox-custom"></span>
                {info.label}
              </label>
            ))}
          </div>
          <div style={styles.periodButtonsContainer}>
            {['1_hours', '24_hours', '7_days', 'all'].map(p => (
              <button
                key={p}
                onClick={() => handlePeriodChange(p)}
                style={{
                  ...styles.periodButton,
                  ...(period === p ? styles.activePeriodButton : {})
                }}
              >
                {p.replace('_', ' ')}
              </button>
            ))}
          </div>
        </div>
        {isLoading ? (
          <p>Loading data...</p>
        ) : chartData.labels.length === 0 || chartData.datasets.length === 0 ? (
          <p>No data available for the selected period and data types.</p>
        ) : activeBottomTab === 'chart' ? (
          <div style={styles.chartContainer}>
            <Line data={chartData} options={chartOptions} />
          </div>
        ) : (
          <div style={styles.tableContainer}>
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.tableHeader}>Timestamp</th>
                  {selectedDataTypes.map(dataType => (
                    <th key={dataType} style={styles.tableHeader}>
                      {availableDataTypes[dataType]?.label || dataType}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {allMeasurements.map((entry, index) => (
                  <tr key={index} style={index % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd}>
                    <td style={styles.tableCell}>{new Date(entry.timestamp).toLocaleString()}</td>
                    {selectedDataTypes.map(dataType => (
                      <td key={dataType} style={styles.tableCell}>
                        {formatSensorValue(entry[dataType])}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  const prepareChartData = () => {
    if (!allMeasurements || allMeasurements.length === 0 || selectedDataTypes.length === 0) {
      return { labels: [], datasets: [] };
    }

    const labels = allMeasurements.map(m => new Date(m.timestamp).toLocaleString());
    const datasets = selectedDataTypes.map(dataType => ({
      label: availableDataTypes[dataType]?.label || dataType,
      data: allMeasurements.map(m => m[dataType]),
      borderColor: getRandomColor(),
      fill: false,
    }));

    return { labels, datasets };
  };

  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod);
    fetchSensorMeasurements(newPeriod);
  };

  const handleDataTypeToggle = (dataType) => {
    setSelectedDataTypes(prev => 
      prev.includes(dataType) 
        ? prev.filter(t => t !== dataType)
        : [...prev, dataType]
    );
  };

  const fetchSensorMeasurements = async (selectedPeriod = '24_hours') => {
    setIsLoading(true);
    try {
      const response = await api.get(`/sensors/get_sensor_measurements/${sensorId}/${selectedPeriod}/`);
      setAllMeasurements(response.data.measurements);
      setAvailableDataTypes(response.data.data_types);
      
      if (selectedDataTypes.length === 0 && Object.keys(response.data.data_types).length > 0) {
        const firstDataType = Object.keys(response.data.data_types)[0];
        setSelectedDataTypes([firstDataType]);
      }
    } catch (error) {
      console.error('Error fetching sensor measurements:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // =============================================
  //=============== EXPORT SECTION ===============
  // =============================================
  const renderExportSection = () => {
    return (
      <div style={styles.exportContainer}>
        <h2 style={styles.exportHeading}>Export Data (In Development)</h2>
        <div style={styles.exportForm}>
          <div style={styles.exportDateRange}>
            <div style={styles.exportField}>
              <label htmlFor="startDate" style={styles.exportLabel}>Start Date:</label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                style={styles.exportInput}
              />
            </div>
            <div style={styles.exportField}>
              <label htmlFor="endDate" style={styles.exportLabel}>End Date:</label>
              <input
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                style={styles.exportInput}
              />
            </div>
          </div>
          <div style={styles.exportOptions}>
            <div style={styles.exportDataSelection}>
              <p style={styles.exportSubHeader}>Data to Export:</p>
              {Object.keys(exportData).map((measurement) => (
                <label key={measurement} style={styles.exportCheckboxLabel}>
                  <input
                    type="checkbox"
                    checked={exportData[measurement]}
                    onChange={() => setExportData({...exportData, [measurement]: !exportData[measurement]})}
                    style={styles.exportCheckbox}
                  />
                  {measurement.charAt(0).toUpperCase() + measurement.slice(1)}
                </label>
              ))}
            </div>
            <div style={styles.exportFormat}>
              <p style={styles.exportSubHeader}>Export Format:</p>
              <div style={styles.exportRadioGroup}>
                <label style={styles.exportRadioLabel}>
                  <input
                    type="radio"
                    value="JSON"
                    checked={exportFormat === 'JSON'}
                    onChange={(e) => setExportFormat(e.target.value)}
                    style={styles.exportRadio}
                  />
                  JSON
                </label>
                <label style={styles.exportRadioLabel}>
                  <input
                    type="radio"
                    value="CSV"
                    checked={exportFormat === 'CSV'}
                    onChange={(e) => setExportFormat(e.target.value)}
                    style={styles.exportRadio}
                  />
                  CSV
                </label>
              </div>
            </div>
          </div>
          <button onClick={handleExport} style={styles.exportButton}>Export</button>
        </div>
      </div>
    );
  };

  const renderLiveMeasurements = () => {
    return (
      <div>
        <h3>Live Measurements</h3>
        {liveMeasurements ? (
          Object.entries(liveMeasurements).map(([key, data]) => (
            key !== 'gps_location' && (
              <div key={key}>
                <span>{data.label}: {data.value} {data.unit}</span>
                <button onClick={() => setSelectedMeasurement(key)}>
                  <TrendingUp size={16} />
                </button>
              </div>
            )
          ))
        ) : (
          <p>No live measurements available for this sensor.</p>
        )}
      </div>
    );
  };

  return (
    <div style={styles.container}>
      {/* Back button */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <button onClick={handleBack} style={styles.backButton}>
          <ArrowLeft size={20} style={{ marginRight: '10px' }} />
          Back to Map
        </button>
      </div>

      <h1>Sensor Data for {sensorInfo ? sensorInfo.name : sensorId}</h1>
      
      {/* Map and Info Box */}
      <div style={styles.mapAndInfoContainer}>
        {/* Map */}
        <div style={styles.mapContainer} ref={mapContainerRef}>
          {sensorLocation ? (
            <MapContainer center={[sensorLocation.lat, sensorLocation.lon]} zoom={17} style={styles.map}>
              <TileLayer 
                url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              />
              <Marker position={[sensorLocation.lat, sensorLocation.lon]} icon={customIcon} />
            </MapContainer>
          ) : (
            <div style={styles.mapPlaceholder}>Loading map...</div>
          )}
        </div>

        {/* Tabbed Info Box */}
        <div style={styles.infoBox}>
          <div style={styles.tabButtonsContainer}>
            {['liveMeasurements', 'sensorInfo', 'sensorHealth', 'alerts', 'notes'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTopTab(tab)}
                style={{
                  ...styles.tab,
                  ...(activeTopTab === tab ? styles.activeTab : {})
                }}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1).replace(/([A-Z])/g, ' $1')}
              </button>
            ))}
          </div>
          <div style={styles.tabContentContainer}>
            {renderTopTabContent()}
          </div>
        </div>
      </div>

      {/* Show Data Section */}
      <div style={styles.showDataContainer}>
        <h2 style={styles.showDataHeading}>Show Data (In Development)</h2>
        <div style={styles.tabsContainer}>
          <div style={styles.tabButtonsContainer}>
            {['chart', 'table'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveBottomTab(tab)}
                style={{...styles.buttonBase, ...styles.secondaryButton, ...(activeBottomTab === tab ? styles.activeButton : {})}}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
          <div style={styles.tabContent}>
            {renderBottomTabContent()}
          </div>
        </div>
      </div>

      {/* Export Section */}
      {renderExportSection()}
    </div>
  );
};

// Helper component for rendering info items
const InfoItem = ({ label, value, helpText }) => (
  <div style={styles.sensorInfoItem}>
    <span style={styles.sensorInfoLabel}>{label}</span>
    <span style={styles.sensorInfoValue}>{value}</span>
    {helpText && <p style={styles.helpText}>{helpText}</p>}
  </div>
);

// Helper function to format sensor info values
const formatSensorInfoValue = (value) => {
  if (value === null || value === undefined) return 'N/A';
  if (typeof value === 'boolean') return value ? 'Yes' : 'No';
  if (typeof value === 'number') return value.toFixed(2);
  if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)) {
    return new Date(value).toLocaleString();
  }
  return value.toString();
};

// Helper function to format dates
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  return new Date(dateString).toLocaleString();
};

export default SensorDataPage;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MapComponent from './components/MapComponent';
import SensorDataPage from './pages/SensorDataPage';

function App() {
  return (
    <Router>
      <div className="App" data-testid="app-component">
        <Routes>
          <Route path="/" element={<MapComponent />} />
          <Route path="/sensor/:sensorId" element={<SensorDataPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

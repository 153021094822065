import axios from 'axios';
import { BASE_URL, API_USERNAME, API_PASSWORD } from './config';

// Helper function to handle API errors
const handleApiError = (error, customMessage) => {
  console.error(`${customMessage}: `, error);
  throw error;
};

// Helper function to log API calls
const logApiCall = (url) => {
  console.log(`API Call: ${url}`);
};

// Axios instance with default configurations
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor to add the JWT token to every request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  console.log(`Making API request to: ${config.url}`);
  return config;
}, (error) => {
  return Promise.reject(error);
});


// Authentication functions

/**
 * Authenticate user and get JWT token
 * @param {string} username - User's username
 * @param {string} password - User's password
 * @returns {Promise<string>} - Access token
 * 
 * Example API call: POST /api/auth/token/
 * Input: { "username": "user", "password": "pass" }
 * Output: { "access": "eyJ0eXAiOiJKV1QiLCJhbGciOiJ...", "refresh": "eyJ0eXAiOiJKV1QiLCJhbGciOiJ..." }
 */
export const authenticate = async () => {
  try {
    console.log(`Authenticating: POST ${BASE_URL}/auth/token/`);
    const response = await api.post('/auth/token/', {
      username: API_USERNAME,
      password: API_PASSWORD,
    });
    localStorage.setItem('access_token', response.data.access);
    return response.data.access;
  } catch (error) {
    console.error('Authentication error:', error.response ? error.response.data : error.message);
    throw error;
  }
};


/**
 * Refresh the access token using the refresh token
 * @returns {Promise<string>} - New access token
 * 
 * Example API call: POST /api/auth/token/refresh/
 * Input: { "refresh": "eyJ0eXAiOiJKV1QiLCJhbGciOiJ..." }
 * Output: { "access": "eyJ0eXAiOiJKV1QiLCJhbGciOiJ..." }
 */
export const refreshToken = async () => {
  const refresh = localStorage.getItem('refresh_token');
  try {
    const response = await api.post('/auth/token/refresh/', { refresh });
    const { access } = response.data;
    localStorage.setItem('access_token', access);
    return access;
  } catch (error) {
    console.error('Token refresh error:', error);
    throw error;
  }
};

// Sensor data functions

/**
 * Fetch sensor data
 * @param {string} sensorId - ID of the sensor
 * @param {string} dataType - Type of data to fetch (e.g., 'temperature', 'humidity')
 * @param {string} timeFilter - Time period for data (e.g., '24_hours', '7_days', 'all')
 * @returns {Promise<Object>} - Sensor data
 * 
 * Example API call: GET /api/sensors/get_data/sensor_001/temperature/24_hours/
 * Output: {
 *   "sensor_id": "sensor_001",
 *   "data_type": "temperature",
 *   "period": "24_hours",
 *   "data": [
 *     {"timestamp": "2023-09-20T12:00:00", "value": 23.5},
 *     {"timestamp": "2023-09-20T13:00:00", "value": 24.0}
 *   ]
 * }
 */
export const fetchSensorData = async (sensorId, dataType, timeFilter) => {
  try {
    const response = await api.get(`/sensors/get_data/${sensorId}/${dataType}/${timeFilter}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching sensor data:', error);
    throw error;
  }
};

/**
 * Fetch information about recorded data types for a sensor
 * @param {string} sensorId - ID of the sensor
 * @returns {Promise<Object>} - Recorded data info
 * 
 * Example API call: GET /api/sensors/get_recorded_data_info/sensor_001/
 * Output: {
 *   "sensor_id": "sensor_001",
 *   "data_info": {
 *     "temperature": {
 *       "label": "Air Temperature",
 *       "unit": "°C"
 *     },
 *     "humidity": {
 *       "label": "Relative Humidity",
 *       "unit": "%"
 *     },
 *     "wind_speed": {
 *       "label": "Wind Speed",
 *       "unit": "m/s"
 *     }
 *   }
 * }
 */
export const fetchSensorRecordedDataInfo = async (sensorId) => {
  try {
    const response = await api.get(`/sensors/get_recorded_data_info/${sensorId}/`);
    console.log('Raw response from get_recorded_data_info:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching sensor recorded data info:', error);
    throw error;
  }
};

/**
 * Fetch available data types for a sensor
 * @param {string} sensorId - ID of the sensor
 * @returns {Promise<string[]>} - Array of data types
 * 
 * Example API call: GET /api/sensors/sensor_data_types/sensor_001/
 * Output: { "data_types": ["temperature", "humidity", "wind_speed"] }
 */
export const fetchSensorDataTypes = async (sensorId) => {
  try {
    const response = await api.get(`/sensors/sensor_data_types/${sensorId}/`);
    return response.data.data_types;
  } catch (error) {
    console.error('Error fetching sensor data types:', error);
    throw error;
  }
};

/**
 * Fetch all available sensor IDs
 * @returns {Promise<string[]>} - Array of sensor IDs
 * 
 * Example API call: GET /api/sensors/get_sensor_ids/
 * Output: { "sensor_ids": ["sensor_001", "sensor_002", "sensor_003"] }
 */
export const fetchSensorIDs = async () => {
  try {
    const response = await api.get('/sensors/get_sensor_ids/');
    return response.data.sensor_ids;
  } catch (error) {
    console.error('Error fetching sensor IDs:', error);
    throw error;
  }
};

/**
 * Update sensor name
 * @param {string} sensorId - ID of the sensor
 * @param {string} newName - New name for the sensor
 * @returns {Promise<Object>} - Update confirmation
 * 
 * Example API call: POST /api/sensors/update_sensor_name/sensor_001/
 * Input: { "name": "Outdoor Weather Sensor" }
 * Output: { "message": "Sensor name updated successfully", "new_name": "Outdoor Weather Sensor" }
 */
export const updateSensorName = async (sensorId, newName) => {
  const encodedName = encodeURIComponent(newName);
  const response = await fetch(`${BASE_URL}/sensors/update_sensor_name/${sensorId}/?name=${encodedName}`);
  if (!response.ok) {
    throw new Error('Failed to update sensor name');
  }
  const data = await response.json();
  return data.name; // Assuming the API returns the updated name
};

/**
 * Update sensor notes
 * @param {string} sensorId - ID of the sensor
 * @param {string} notes - New notes for the sensor
 * @returns {Promise<Object>} - Update confirmation
 * 
 * Example API call: POST /api/sensors/update_sensor_notes/sensor_001/
 * Input: { "notes": "Located on the rooftop." }
 * Output: { "message": "Sensor notes updated successfully", "new_notes": "Located on the rooftop." }
 */
export const updateSensorNotes = async (sensorId, notes) => {
  try {
    const response = await api.post(`/sensors/update_sensor_notes/${sensorId}/`, { notes });
    return response.data;
  } catch (error) {
    console.error('Error updating sensor notes:', error);
    throw error;
  }
};

/**
 * Update sensor location description
 * @param {string} sensorId - ID of the sensor
 * @param {string} locationDescription - New location description
 * @returns {Promise<Object>} - Update confirmation
 * 
 * Example API call: POST /api/sensors/update_sensor_location_description/sensor_001/
 * Input: { "location_description": "Installed on the northern wall." }
 * Output: { "message": "Sensor location description updated successfully", "new_location_description": "Installed on the northern wall." }
 */
export const updateSensorLocationDescription = async (sensorId, locationDescription) => {
  try {
    const response = await api.post(`/sensors/update_sensor_location_description/${sensorId}/`, { location_description: locationDescription });
    return response.data;
  } catch (error) {
    console.error('Error updating sensor location description:', error);
    throw error;
  }
};

/**
 * Fetch sensor metadata
 * @param {string} sensorId - ID of the sensor
 * @returns {Promise<Object>} - Sensor metadata
 * 
 * Example API call: GET /api/sensors/get_sensor_metadata/sensor_001/
 * Output: {
 *   "sensor_id": "sensor_001",
 *   "name": "Outdoor Weather Sensor",
 *   "sensor_type": "weather",
 *   "model": "WX200",
 *   "date_added": "2023-09-15T10:30:00Z",
 *   "firmware_version": "1.02",
 *   "manufacturer": "WeatherCorp",
 *   "location_description": "Rooftop",
 *   "customer_notes": "Key weather sensor",
 *   ...
 * }
 */
export const fetchSensorMetadata = async (sensorId) => {
  try {
    const response = await api.get(`/sensors/get_sensor_metadata/${sensorId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching sensor metadata:', error);
    throw error;
  }
};

/**
 * Add a sensor to the user's dashboard
 * @param {string} sensorId - ID of the sensor
 * @param {string} sensorPassword - Password or activation code for the sensor
 * @returns {Promise<Object>} - Confirmation message
 * 
 * Example API call: POST /api/sensors/add_sensor/sensor_001/sensor_password123/
 * Output: { "message": "Sensor added successfully" }
 */
export const addSensorToDashboard = async (sensorId, sensorPassword) => {
  try {
    const response = await api.post(`/sensors/add_sensor/${sensorId}/${sensorPassword}/`);
    return response.data;
  } catch (error) {
    console.error('Error adding sensor to dashboard:', error);
    throw error;
  }
};

/**
 * Fetch sensor health data
 * @param {string} sensorId - ID of the sensor
 * @param {string} healthType - Type of health data to fetch
 * @param {string} period - Time period for data
 * @returns {Promise<Object>} - Sensor health data
 * 
 * Example API call: GET /api/sensors/get_health/sensor_001/battery_voltage/7_days/
 * Output: {
 *   "sensor_id": "sensor_001",
 *   "health_type": "battery_voltage",
 *   "period": "7_days",
 *   "data": [
 *     {"timestamp": "2023-09-19T14:00:00", "value": 4.2},
 *     {"timestamp": "2023-09-20T14:00:00", "value": 4.1}
 *   ]
 * }
 */
export const fetchSensorHealth = async (sensorId, healthType, period) => {
  try {
    const response = await api.get(`/sensors/get_health/${sensorId}/${healthType}/${period}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching sensor health data:', error);
    throw error;
  }
};

/**
 * Fetch available health data types for a sensor
 * @param {string} sensorId - ID of the sensor
 * @returns {Promise<string[]>} - Array of health data types
 * 
 * Example API call: GET /api/sensors/health_types/sensor_001/
 * Output: { "health_types": ["battery_voltage", "signal_strength", "uptime"] }
 */
export const fetchSensorHealthTypes = async (sensorId) => {
  try {
    const response = await api.get(`/sensors/health_types/${sensorId}/`);
    return response.data.health_types;
  } catch (error) {
    console.error('Error fetching sensor health types:', error);
    throw error;
  }
};

/**
 * Fetch sensor health information
 * @param {string} sensorId - ID of the sensor
 * @returns {Promise<Object>} - Sensor health information
 * 
 * Example API call: GET /api/sensors/get_health_info/sensor_001/
 * Output: {
 *   "sensor_id": "sensor_001",
 *   "health_info": {
 *     "battery_voltage": {
 *       "label": "Battery Voltage",
 *       "description": "Current battery voltage of the sensor",
 *       "value": 4.2
 *     },
 *     "uptime": {
 *       "label": "Uptime",
 *       "description": "Total time the sensor has been operational since last reset",
 *       "value": "5 days, 3 hours"
 *     },
 *     ...
 *   }
 * }
 */
export const fetchSensorHealthInfo = async (sensorId) => {
  try {
    const response = await api.get(`/sensors/get_health_info/${sensorId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching sensor health info:', error);
    throw error;
  }
};

/**
 * Fetch data for all sensors
 * @param {string[]} sensorIDs - Array of sensor IDs
 * @returns {Promise<Object[]>} - Array of sensor data objects
 * 
 * Example API call: GET /api/sensors/get_all_sensors_data/
 * Output: [
 *   {
 *     "id": "sensor_001",
 *     "lat": 43.075968,
 *     "lon": -107.290283,
 *     "metadata": { ... },
 *     "last_measurement": { ... }
 *   },
 *   ...
 * ]
 */
export const fetchAllSensorsData = async (sensorIDs) => {
  try {
    const response = await api.post('/sensors/get_all_sensors_data/', { sensor_ids: sensorIDs });
    return response.data;
  } catch (error) {
    console.error('Error fetching all sensors data:', error);
    throw error;
  }
};

export const fetchSensorGPSLocation = async (sensorId) => {
  try {
    const response = await api.get(`/sensors/get_data/${sensorId}/gps_location/last/`);
    return response.data.data[0].value;
  } catch (error) {
    console.error('Error fetching sensor GPS location:', error);
    throw error;
  }
};

export const fetchSensorHistoricalData = async (sensorId, dataType, period) => {
  try {
    const response = await api.get(`/sensors/get_data/${sensorId}/${dataType}/${period}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching sensor historical data:', error);
    throw error;
  }
};

/**
 * Fetch the most recent timestamp for a sensor
 * @param {string} sensorId - ID of the sensor
 * @returns {Promise<string>} - The most recent timestamp
 * 
 * Example API call: GET /api/sensors/get_latest_timestamp/sensor_001/
 * Output: { "sensor_id": "sensor_001", "latest_timestamp": "2024-09-30T02:57:34.895Z" }
 */
export const fetchLatestSensorTimestamp = async (sensorId) => {
  try {
    const url = `/sensors/get_latest_timestamp/${sensorId}/`;
    logApiCall(BASE_URL + url);
    const response = await api.get(url);
    return response.data.latest_timestamp;
  } catch (error) {
    handleApiError(error, 'Error fetching latest sensor timestamp');
  }
};

export const fetchAllLatestSensorData = async () => {
  try {
    const response = await api.get('/sensors/get_all_latest_sensor_data/');
    return response.data.sensors;
  } catch (error) {
    console.error('Error fetching all latest sensor data:', error);
    throw error;
  }
};

/**
 * Fetch the latest data for a specific sensor
 * @param {string} sensorId - ID of the sensor
 * @returns {Promise<Object>} - Latest sensor data
 * 
 * Example API call: GET /api/sensors/get_latest_sensor_data/sensor_001/
 * Output: {
 *   "sensor_id": "sensor_001",
 *   "timestamp": "2024-10-06T23:12:51.299Z",
 *   "gps_location": {"lat": 41.3839, "lon": -106.2800},
 *   "data": {
 *     "temperature": {
 *       "value": 12.31,
 *       "unit": "°C",
 *       "label": "Temperature"
 *     },
 *     "humidity": {
 *       "value": 45.6,
 *       "unit": "%",
 *       "label": "Relative Humidity"
 *     },
 *     "wind_speed": {
 *       "value": 3.2,
 *       "unit": "m/s",
 *       "label": "Wind Speed"
 *     }
 *   }
 * }
 */
export const fetchLatestSensorData = async (sensorId) => {
  try {
    const response = await api.get(`/sensors/get_latest_sensor_data/${sensorId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching latest sensor data:', error);
    throw error;
  }
};

// Add this new function to your existing api.js file

export const fetchAllSensorInfo = async (sensorId) => {
  try {
    const response = await api.get(`/sensors/get_all_sensor_info/${sensorId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all sensor info:', error);
    throw error;
  }
};

export default api;
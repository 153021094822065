import React, { useState, useCallback } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import L from 'leaflet';

// Define icons for active and inactive states
const iconWidth = 20; // You can adjust this base width as needed
const iconHeight = Math.round((559 / 300) * iconWidth); // Calculating height to maintain aspect ratio

const inactiveIcon = new L.Icon({
  iconUrl: require('../assets/MapIcon.png'),
  iconSize: [iconWidth, iconHeight],
  iconAnchor: [iconWidth / 2, iconHeight] // Anchor point at the bottom center of the icon
});

const activeIcon = new L.Icon({
  iconUrl: require('../assets/MapIcon.png'),
  iconSize: [iconWidth, iconHeight],
  iconAnchor: [iconWidth / 2, iconHeight] // Anchor point at the bottom center of the icon
});

// Define styles for the component
const styles = {
  container: {
    padding: '10px',
    textAlign: 'center',
  },
  header: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  infoItem: {
    margin: '5px 0',
  },
  label: {
    fontWeight: 'bold',
  },
  button: {
    marginTop: '10px',
    padding: '5px 10px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

const SensorMarkerComponent = ({ sensor }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);

  const handleMarkerClick = useCallback(() => {
    setActive(true);
  }, []);

  const formatMeasurement = (value, unit) => {
    return `${typeof value === 'number' ? value.toFixed(2) : value} ${unit}`;
  };

  const getTimeSinceLastUpdate = (timestamp) => {
    if (!timestamp) return 'N/A';
    
    const lastUpdate = new Date(timestamp);
    const now = new Date();
    const diff = now - lastUpdate;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  };

  const gpsLocation = sensor.data.gps_location?.value;

  return (
    <Marker
      position={[gpsLocation.lat, gpsLocation.lon]}
      icon={active ? activeIcon : inactiveIcon}
      eventHandlers={{ click: handleMarkerClick }}
    >
      <Popup>
        <div style={styles.container}>
          <h3 style={styles.header}>{sensor.sensor_id}</h3>
          <p style={styles.infoItem}>
            <span style={styles.label}>Last Updated:</span> {getTimeSinceLastUpdate(sensor.timestamp)}
          </p>
          {Object.entries(sensor.data).map(([key, data]) => (
            key !== 'gps_location' && (
              <div key={key}>
                <p style={styles.infoItem}>
                  <span style={styles.label}>{data.label}:</span> {formatMeasurement(data.value, data.unit)}
                </p>
              </div>
            )
          ))}
          <button 
            onClick={() => navigate(`/sensor/${sensor.sensor_id}`)}
            style={styles.button}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#45a049'}
            onMouseLeave={(e) => e.target.style.backgroundColor = '#4CAF50'}
          >
            View Data
          </button>
        </div>
      </Popup>
    </Marker>
  );
};

export default SensorMarkerComponent;
import React, { useState, useEffect, useMemo } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Leaflet styles
import { fetchAllLatestSensorData } from '../api'; // Import the new API function
import SensorMarkerComponent from './SensorMarkerComponent'; // Import sensor marker
import { BASE_URL } from '../config';

const MapComponent = () => {
  const [sensorData, setSensorData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchSensors = async () => {
      try {
        console.log(`Fetching all latest sensor data: GET ${BASE_URL}/sensors/get_all_latest_sensor_data/`);
        const sensors = await fetchAllLatestSensorData();
        console.log('Fetched all latest sensor data:', sensors);
        setSensorData(sensors);
      } catch (error) {
        console.error('Error in fetchSensors:', error);
        setError(`Failed to fetch sensor data: ${error.message}`);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchSensors();
  }, []);

  const memoizedSensorMarkers = useMemo(() => {
    return sensorData.map((sensor) => {
      const gpsLocation = sensor.data.gps_location?.value;
      if (gpsLocation) {
        return <SensorMarkerComponent key={sensor.sensor_id} sensor={sensor} />;
      }
      return null;
    }).filter(marker => marker !== null);
  }, [sensorData]);

  if (error) {
    return <div style={{ color: 'red', padding: '10px' }}>{error}</div>;
  }

  return (
    <div data-testid="map-component" style={{ position: 'relative' }}>
      {loading && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          zIndex: 1000
        }}>
          Loading Sensors...
        </div>
      )}
      <MapContainer center={[40.0, -105.0]} zoom={6} style={{ height: '100vh', width: '100%' }}>
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
          attribution="&copy; OpenStreetMap contributors &copy; CARTO"
        />
        {memoizedSensorMarkers}
      </MapContainer>
    </div>
  );
};

export default MapComponent;
